<template>
    <div class="d-flex align-items-center" style="min-height: 100vh;">
        <div class="col-sm-8 col-md-6 col-lg-4 mx-auto" style="min-width: 300px;">
            <div class="text-center mt-5 mb-1">
                <img src="@/assets/images/1.png" alt="Luma" width="200" class="mb-3">
            </div>
            <div class="card navbar-shadow">
                <div class="card-header text-center">
                    <div class="media">
                        <div class="media-body"><h4 class="card-title">Cadastrar Senha</h4>
                            <p class="card-subtitle text-50">Realize o cadastro de sua senha para acessar a
                                Plataforma</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form novalidate="novalidate" id="form" @submit.prevent="save">

                        <div role="group" class="form-group">
                            <label for="password" class="d-block form-label">Senha:</label>
                            <div class="bv-no-focus-ring">
                                <div role="group" class="input-group input-group-merge form-control-prepended">
                                    <input id="password" type="password" placeholder="Senha" required="required"
                                           aria-required="true" name="password" v-model="formData.password"
                                           class="form-control form-control-prepended">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fas fa-key"></span>
                                        </div>
                                    </div>
                                </div>
                                <span class="text-muted fs-10">Mínimo oito caracteres</span>
                            </div>
                        </div>

                        <div role="group" class="form-group">
                            <label for="password2" class="d-block form-label">Confirmar Senha:</label>
                            <div class="bv-no-focus-ring">
                                <div role="group" class="input-group input-group-merge form-control-prepended">
                                    <input id="password2" name="password2" type="password" placeholder="Confirmar Senha"
                                           required="required" aria-required="true" v-model="formData.password2"
                                           class="form-control form-control-prepended">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fas fa-check"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn btn-primary btn-block">Salvar</button>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="text-center text-black-50">
                        Já é Mentor?
                        <a href="/login" class="" target="_self">Login</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {ValidateForm} from "@/components/composables/cruds";
import http from "@/http/index";

export default {
    data() {
        return {
            formData: {
                password: '',
                password2: '',
            }
        }
    },
    methods: {
        save() {
            this.showSpinner = true
            const required = {
                password: 'Senha',
                password2: 'Confirmar Senha'
            };
            if (!ValidateForm(this.$root, this.formData, required)) {
                return;
            }

            this.formData.token = this.$route.params.token;
            if (this.formData.password.length < 8) {
                this.$notifyError('A senha deve possuir no mínimo oito caracteres!');
                return;
            }

            if (this.formData.password !== this.formData.password2) {
                this.$notifyError('As senhas não são iguais!');
                return;
            }

            http.post('mentors/set-password', this.formData)
                .then(() => {
                    this.$notifySuccess('Senha cadastrada com sucesso!');
                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 800)
                })
                .catch((error) => {
                    console.error('Password Register: ' + error);
                    this.$notifyError('Erro ao cadastrar senha!');
                })
        },
    },
}
</script>
